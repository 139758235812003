<template>
    <div class="page_container" :class="{'straight_length': borderPosition}">
        <div class="page_head">
            <div class="page_head-title">
                ACCOUNT SETTINGS
            </div>
        </div>
        <div class="page_content">
            <div class="user_data">
                <div class="user_info">
                    <div class="user_avatar">
                    </div>
                    <div class="user_name">
                        Johanna Doe
                    </div>
                    <div class="user_edit">

                    </div>
                </div>
                <div class="upload_file">
                    <label class="upload_label">
                        <span>Upload New photo</span>
                        <b-form-file v-model="file2" class="mt-3" plain></b-form-file>
                    </label>

                    <div class="delete_btn">Delete photo</div>
                </div>
            </div>

            <div class="inputs_content">
                <div class="dual_inputs">
                    <div class="input_item">
                        <label>Your name(not company name)</label>
                        <b-form-input
                                placeholder="John Doe"
                                v-model="account_settings.name"
                                type="text"
                                trim
                        ></b-form-input>
<!--                        <span v-if="createLeadErrors['street_address']" class="text_danger mt-1">{{createLeadErrors['street_address'][0]}}</span>-->
                    </div>
                    <div class="input_item">
                        <label>Login email(not used for lead notification)</label>
                        <b-form-input
                                placeholder="John@example.com"
                                v-model="account_settings.email"
                                type="email"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="createLeadErrors['street_address']" class="text_danger mt-1">{{createLeadErrors['street_address'][0]}}</span>-->
                    </div>
                </div>

                <div class="dual_inputs">
                    <div class="input_item">
                        <label>Your phone(not used for lead notifications)</label>
                        <b-form-input
                                placeholder="614-454-3225"
                                v-model="account_settings.phone"
                                type="number"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="createLeadErrors['street_address']" class="text_danger mt-1">{{createLeadErrors['street_address'][0]}}</span>-->
                    </div>
                    <div class="input_item">
                        <label>Time Zone</label>
                        <b-form-input
                                placeholder="CST6CDT"
                                v-model="account_settings.time_zone"
                                type="text"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="createLeadErrors['street_address']" class="text_danger mt-1">{{createLeadErrors['street_address'][0]}}</span>-->
                    </div>
                </div>

                <div class="dual_inputs">
                    <div class="input_item">
                        <label>New Password</label>
                        <b-form-input
                                placeholder=""
                                v-model="account_settings.new_password"
                                type="password"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="createLeadErrors['street_address']" class="text_danger mt-1">{{createLeadErrors['street_address'][0]}}</span>-->
                    </div>
                    <div class="input_item">
                        <label>Confirm New Password</label>
                        <b-form-input
                                placeholder=""
                                v-model="account_settings.confirm_new_password"
                                type="password"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="createLeadErrors['street_address']" class="text_danger mt-1">{{createLeadErrors['street_address'][0]}}</span>-->
                    </div>
                </div>

                <div class="dual_inputs">
                    <div class="input_item">
                        <label>Default email signature</label>
                        <b-form-textarea
                                id="signature"
                                v-model="account_settings.email_signature"
                                placeholder="Regards,

The Name Surname
656-454-5454"
                                rows="4"
                                max-rows="6"
                        ></b-form-textarea>

                        <!--                        <span v-if="createLeadErrors['street_address']" class="text_danger mt-1">{{createLeadErrors['street_address'][0]}}</span>-->
                    </div>
                    <div class="input_item">
                        <label>Brokerage(Optional)</label>
                        <b-form-input
                                placeholder=""
                                v-model="account_settings.brokerage"
                                type="text"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="createLeadErrors['street_address']" class="text_danger mt-1">{{createLeadErrors['street_address'][0]}}</span>-->
                    </div>
                </div>

                <div class="save_btn-container">
                    <b-button class="save_btn">Save</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Account",
        data() {
            return {
                borderPosition: true,
                file2: null,
                account_settings: {
                    name: null,
                    email: null,
                    phone: null,
                    time_zone: null,
                    new_password: null,
                    confirm_new_password: null,
                    email_signature: '',
                    brokerage: null
                }
            }
        },
        created() {
            alert.$on("clickOn",border =>{
                this.borderPosition = border;
            })
        },
    }
</script>

<style scoped lang="scss">
.page {
    &_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1500px;
        width: 100%;
        margin: 0 auto;
        transition: .4s;
        &.straight_length {
            max-width: 1700px;
            transition: .4s;
        }
    }
    &_head {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 30px;

        &-title {
            color: $main_blue;
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 0;
            text-transform: uppercase;
        }
    }
    &_content {
        display: flex;
        width: 100%;
        padding: 0 30px;
        flex-direction: column;
        .user {
            &_data {
                display: flex;
                flex-direction: column;
                & .upload_file {
                    display: flex;
                    flex-direction: column;
                    width: 150px;
                    align-items: center;
                    padding-top: 15px;
                    & .upload_label {
                        position: relative;
                        width: 150px;
                        height: 30px;
                        border-radius: 10px;
                        border: 1px solid $main_blue;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }
                    ::v-deep input[type="file"]{
                        color: transparent;
                        width: 150px;
                        height: 30px;
                        position: absolute;
                        background: transparent;
                        /*opacity: 0;*/
                        top: -15px;
                        padding: 0;
                        margin: 0;
                        cursor: pointer;
                    }
                    input[type="file"]:before {
                        content: "Browse";
                        background: #fff;
                        width: 140px;
                        height: 30px;
                        display: block;
                        position: relative;
                        margin: 0;
                        left: 0;
                        top: -30px;
                    }
                    & .delete_btn {
                        color: red;
                        cursor: pointer;
                    }
                }
            }
            &_info {
                display: flex;
                align-items: center;
            }
            &_avatar {
                width: 120px;
                height: 120px;
                background: url("~@/assets/images/profile_user.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
            &_name {
                color: $black;
                font-weight: 600;
                padding: 0 30px;
            }
            &_edit {
                width: 14px;
                height: 14px;
                background: url("~@/assets/images/edit_icon.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                cursor: pointer;
            }
        }
        & .inputs_content {
            & .dual_inputs {
                display: flex;
                & .input_item {
                    padding: 15px 60px 15px 0;
                    width: 400px;

                    & label {
                        color: $black;
                        font-weight: 500;
                        font-size: 16px;
                    }
                    & input {
                        height: 40px;
                        border: 1px solid #798387;
                        border-radius: 5px;
                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }

                }
            }
            & .save_btn {
                background: $light_blue;
                box-shadow: 0 3px 6px #00000029;
                border-radius: 5px;
                width: 180px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-weight: 400;
                font-size: 18px;
                border: none;
                &-container {
                    padding: 55px 0;
                }
            }


        }

    }
}
</style>